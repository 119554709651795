@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .border-gradient {
        border-width: 3px;
        border-style: solid;
        border-radius: 0.375rem;
        /* border-image: linear-gradient(to bottom, #14E1D5 0%, #5763DB 25%, #CC448B 50%, #EF7C2F 75%); */
        border-image: linear-gradient(90deg, #41a2d9 0%, #9054b4 35%, #c34692 67%, #ec7638 100%);

    }

    .text-gradient-horizontal {
        background-color: #14E1D5;
        /* background-image: linear-gradient(to right, #14E1D5 0%, #5763DB 25%, #CC448B 50%, #EF7C2F 75%); */
        background-image: linear-gradient(90deg, #41a2d9 0%, #9054b4 35%, #c34692 67%, #ec7638 100%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }

    .activelink {
        position: relative;
    }

    .activelink::before {
        content:"";
        position: absolute;
        top: 45px;
        /* background: linear-gradient(to right, #14E1D5 0%, #5763DB 25%, #CC448B 50%, #EF7C2F 75%); */
        background-image: linear-gradient(90deg, #41a2d9 0%, #9054b4 35%, #c34692 67%, #ec7638 100%);
        box-shadow: 0 0 20px rgb(255 255 255 / 0.3);
        display: block;
        height: 5px;
        width: 82%;
        margin-left: -7px;
    }

    .gradientbutton {
        border-radius: 9999px;
        padding: 12px;
        padding-left: 20px;
        padding-right: 20px;
        /* background-color: #14e1d5; */
        /* background-image: linear-gradient(90deg, #14e1d5 0%, #5763db 15%, #cc448b 85%, #ef7c2f 100%); */
        /* background-image: linear-gradient(90deg, #41a2d9 0%, #9054b4 35%, #c34692 67%, #ec7638 100%); */
        /* box-shadow: 0 0 15px rgb(255 255 255 / 0.2); */
    }

    
    .gradientbuttondisabled {
        border-radius: 9999px;
        padding: 12px;
        padding-left: 20px;
        padding-right: 20px;
        /* background-color: #14e1d5; */
        /* background-image: linear-gradient(90deg, #14e1d5 0%, #5763db 15%, #cc448b 85%, #ef7c2f 100%); */
        /* background-image: linear-gradient(90deg, #808080 0%, #817E7E 35%, #979797 67%, #808080 100%); */
        /* box-shadow: 0 0 15px rgb(255 255 255 / 0.2); */
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #1A1B31; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}